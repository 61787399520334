:root{
    --font-color-white: #FFFFFF;
    --font-color-black: #212529;
    --font-color-blue: #00134D;
    --font-color-gray: #E9ECF1;
    --background-color-button-one: #00134D;
    --background-color-button-three: #FFFFFF;
    --background-color-one: #FFFFFF;
    --background-color-two: #E9ECF1;
    --background-color-three: #00134D;
}

@media only screen and (max-width : 576px) {

    /* ====================================================== */

    /* ---------- Navbar ---------- */

    .navbar{
        background-color: var(--background-color-one);
    }

    .navbar-collapse {
        margin-top: 12px;
    }

    .nav-brand{
        padding-left: 12px;
    }

    .nav-toggle{
        margin-right: 12px;
    }

    .nav-link{
        font-size: 16px;
        margin-left: 0px;
        background-color: var(--background-color-one);
        padding-left: 12px;
    }

    .nav-btn{
        margin-left: 0px;
        background-color: var(--background-color-one);
        padding: 10px 0 12px 12px;
    }

    .nav-link-scrolled{
        font-size: 16px;
        margin-left: 0px;
        padding-left: 12px;
    }

    .nav-link:hover,
    .nav-link-scrolled:hover{
        font-weight: 600;
        color: var(--font-color-black);
    }

    /* ---------- End Navbar ---------- */

    /* ====================================================== */

    /* ==========================================================================================*/
    /* ======================================== HOME PAGE =======================================*/
    /* ==========================================================================================*/


    /* ====================================================== */

    /* ---------- Main Content ---------- */

    #main-content{
        margin-top: 120px;
    }

    #main-content .main-title h1{
        font-size: 32px;
    }

    #main-content .main-title p{
        font-size: 16px;
    }

    /* ---------- End Main Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- About Us Content ---------- */

    #about-us-content{
        margin-top: 60px;
    }

    #about-us-content .about-us-description h1{
        font-size: 24px;
    }

    #about-us-content .about-us-description p{
        font-size: 16px;
    }

    #about-us-content .value-description{
        margin-top: 0px;
    }

    #about-us-content .value-description h6{
        font-size: 18px;
    }

    #about-us-content .value-description p{
        font-size: 14px;
    }

    /* ---------- End About Us Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Vission Mission Content ---------- */

    #vission-mission-content{
        margin-top: 60px;
    }

    #vission-mission-content h1{
        font-size: 20px;
    }

    #vission-mission-content .mission-card{
        height: 100%;
    }

    #vission-mission-content .mission-card h6{
        font-size: 18px;
    }

    #vission-mission-content .mission-card p{
        font-size: 14px;
        color: var(--font-color-black);
    }

    /* ---------- End Vission Mission Content ---------- */


    /* ====================================================== */

    /* ---------- Service Content ---------- */

    #service-content{
        margin-top: 60px;
    }

    #service-content .service-description h1{
        font-size: 24px;
    }

    #service-content .service-description p{
        font-size: 16px;
        margin: auto 0;
        color: var(--font-color-black);
    }

    #service-content .service-sample p{
        font-size: 14px;
        margin: auto 0;
        color: var(--font-color-black);
    }

    /* ---------- End Service Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Partner Content ---------- */

    #partner-content{
        margin-top: 60px;
    }

    #partner-content h1{
        font-size: 20px;
        text-align: center;
    }

    #partner-content .logo img{
        width: 100px;
        height: 30px;
        margin-left: 20px;
        margin-right: 20px;
    }

    /* ---------- End Partner Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Department Content ---------- */

    #department-content{
        margin-top: 60px;
    }

    #department-content h1{
        font-size: 24px;
        color: var(--font-color-black);
    }

    #department-content .card-department{
        border-radius: 20px;
    }

    #department-content .department-title p{
        font-size: 18px;
    }

    #department-content .department-short-description p{
        font-size: 16px;
    }

    #department-content .department-point p{
        font-size: 16px;
    }

    /* ---------- End Department Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Benefit Content ---------- */

    #benefit-content{
        margin-top: 60px;
    }

    /* ---------- End Benefit Content ---------- */

    /* ====================================================== */


    /* ---------- International Panelist Content ---------- */

    #international-panelist-content{
        margin-top: 60px;
    }

    /* ---------- End International Panelist Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Mentor Content ---------- */

    #mentor-content{
        margin-top: 60px;
    }

    /* ---------- End Mentor Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Testimony Content ---------- */

    #testimony-content{
        margin-top: 60px;
    }

    .testimony-list{
        flex-direction: column-reverse;
        display: flex;
        gap: 20px;
    }

    .testimony-list h1{
        font-size: 24px;
        text-align: center;
    }

    .testimony-list .swiper {
        width: 100%;
        height: 100%;
    }

    .testimony-list .swiper-slide {
        font-size: 16px;
        padding: 10px;
    }

    .testimony-list .swiper-slide .profile p{
        margin: auto 0;
    }

    .testimony-list .swiper-slide p{
        margin-top: 20px;
    }
    
    .testimony-list .swiper-slide h5{
        font-size: 16px;
    }

    /* ---------- End Testimony Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Contact Content ---------- */

    #contact-content{
        margin-top: 60px;
    }

    #contact-content .contact-information h1{
        font-size: 16px;
    }

    #contact-content .contact-information p{
        font-size: 16px;
    }

    #contact-content .contact-form h1{
        margin-top: 30px;
        font-size: 24px;
    }

    #contact-content .contact-form p{
        font-size: 16px;
    }

    /* ---------- End Contact Content ---------- */

    /* ====================================================== */


    /* ==========================================================================================*/
    /* ====================================== END HOME PAGE =====================================*/
    /* ==========================================================================================*/


    /* =============================================================================================*/
    /* ======================================== PRODUCT PAGE =======================================*/
    /* =============================================================================================*/


    /* ====================================================== */

    /* ---------- List Product Content ---------- */

    .product-head-title{
        margin-top: 120px;
    }

    .product-head-title h1{
        font-size: 24px;
    }

    .product-list-query{
        margin-top: 20px;
    }

    .product-list-query h5{
        font-size: 16px;
    }

    /* ---------- End List Product Content ---------- */

    /* ====================================================== */


    /* ====================================================== */

    /* ---------- Detail Product Content ---------- */

    .detail-product-back-to{
        margin-top: 120px;
    }

    .detail-product-back-to h1{
        font-size: 16px;
    }

    .detail-product{
        margin-top: 20px;
    }

    .detail-product-profile-head{
        margin-top: 20px;
    }

    .detail-product-profile-head h5{
        font-size: 16px;
        color: var(--font-color-black);
        margin: auto 0;
    }

    .detail-product-profile-head h1{
        font-size: 20px;
    }

    .detail-product-profile-head p{
        font-size: 18px;
    }

    .detail-product hr{
        margin: 30px 0;
    }

    .detail-product-profile-tile h2{
        font-size: 16px;
    }

    .detail-product-profile-tile .active{
        font-weight: bold;
        color: var(--font-color-black);
    }

    .addition-detail-product{
        margin-top: 20px;
    }

    .description-detail-product p{
        font-size: 16px;
    }

    .benefit-detail-product p{
        font-size: 16px;
    }

    .review-detail-product p{
        font-size: 16px;
    }

    /* ---------- End Detail Product Content ---------- */

    /* ====================================================== */


    /* ============================================ */

    /* ------ Dropdown Content ------*/

    .dropdown-menu {
        display: block;
        top: 40px;
        font-size: 14px;
    }

    .dropdown-menu-item {
        padding: 8px 0;
        cursor: pointer;
    }

    .dropdown-menu-item:hover {
        background-color: #FAFAFA;
        border-radius: 6px;
    }

    /* ------ End Dropdown Content ------*/

    /* ============================================ */


    /* =============================================================================================*/
    /* ====================================== END PRODUCT PAGE =====================================*/
    /* =============================================================================================*/


    /* =============================================================================================*/
    /* ========================================= EVENT PAGE ========================================*/
    /* =============================================================================================*/


    /* ====================================================== */

    /* ---------- Event Content ---------- */

    .event-head-title{
        margin-top: 120px;
    }

    .event-head-title h1{
        font-size: 24px;
    }

    .list-event{
        margin-top: 20px;
    }

    .list-event h1{
        font-size: 18px;
    }

    .list-event h2{
        font-size: 16px;
    }

    .list-event p{
        font-size: 14px;
    }

    /* ---------- End Event Content ---------- */

    /* ====================================================== */


    /* =============================================================================================*/
    /* ======================================= END EVENT PAGE ======================================*/
    /* =============================================================================================*/


    /* ====================================================== */

    /* ---------- Footer Content ---------- */

    #footer-content .footer-profile p{
        font-size: 16px;
        color: var(--font-color-white);
        margin: auto 0;
    }

    #footer-content .footer-quick {
        gap: 20px;
        height: 100%;
        margin-top: 10px;
    }

    #footer-content .footer-quick p{
        font-size: 14px;
    }

    #footer-content .footer-quick p:hover{
        font-weight: 600;
    }

    #footer-content hr{
        margin-top: 35px;
        margin-bottom: 35px;
    }

    /* ---------- End Footer Content ---------- */

    /* ====================================================== */

}