*{
    padding: 0;
    margin: 0;
    position: relative;
    font-family: 'Helvetica', sans-serif;
}

body, html {
    scroll-behavior: smooth;
}

:root{
    --font-color-white: #FFFFFF;
    --font-color-black: #212529;
    --font-color-blue: #00134D;
    --font-color-gray: #E9ECF1;
    --background-color-button-one: #00134D;
    --background-color-button-three: #FFFFFF;
    --background-color-one: #FFFFFF;
    --background-color-two: #E9ECF1;
    --background-color-three: #00134D;
}

/* ====================================================== */

/* ---------- Navbar ---------- */

.navbar{
    padding: 10px 0;
    height: 90px;
    background-color: transparent;
}

.navbar-scrolled {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    transition: 0.3s all ease-in-out;
}

.navbar .active{
    font-weight: bold;
}

.nav-link{
    font-size: 18px;
    margin-left: 25px;
    color: var(--font-color-black);
    font-weight: 500;
    text-decoration: none;
    list-style: none;
}

.nav-link-scrolled{
    font-size: 18px;
    margin-left: 25px;
    color: var(--font-color-black);
    font-weight: 500;
    text-decoration: none;
    list-style: none;
}

.nav-link:hover,
.nav-link-scrolled:hover{
    font-weight: bold;
    color: var(--font-color-black);
}

/* ---------- End Navbar ---------- */

/* ====================================================== */



/* ==========================================================================================*/
/* ======================================== HOME PAGE =======================================*/
/* ==========================================================================================*/


/* ====================================================== */

/* ---------- Main Content ---------- */

#main-content{
    margin-top: 90px;
}

#main-content .main-title {
    margin: auto 0;
}

#main-content .main-title h1{
    font-size: 56px;
    font-weight: bold;
    color: var(--font-color-black);
}

#main-content .main-title p{
    font-size: 18px;
    margin: auto 0;
    color: var(--font-color-black);
}

#main-content .main-line{
    background: transparent;
    fill: transparent;
    display: block;
}

#main-content .main-value p{
    font-size: 16px;
    font-weight: bold;
    color: var(--font-color-black);
}

/* ---------- End Main Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Impact Content ---------- */

#impact-content{
    margin-top: 40px;
}

#impact-content .impact-box{
    padding: 25px 10px;
    border: 1px solid var(--font-color-gray);
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

#impact-content .impact-box .count{
    font-size: 18px;
    font-weight: bold;
    color: var(--font-color-blue);
}

#impact-content .impact-box .count{
    color: var(--font-color-black);
}

#impact-content .impact-box .subtitle{
    font-size: 14px;
    color: var(--font-color-black);
}

/* ---------- End Impact ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- About Us Content ---------- */

#about-us-content{
    margin-top: 100px;
}

#about-us-content .about-us-description h1{
    font-size: 36px;
    color: var(--font-color-black);
}

#about-us-content .about-us-description p{
    font-size: 18px;
    color: var(--font-color-black);
}

#about-us-content .value-description{
    margin-top: 50px;
}

#about-us-content .value-description h6{
    font-size: 20px;
    font-weight: bold;
    color: var(--font-color-black);
}

#about-us-content .value-description p{
    font-size: 16px;
    margin: auto 0;
    color: var(--font-color-black);
}

/* ---------- End About Us Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Vission Mission Content ---------- */

#vission-mission-content{
    margin-top: 100px;
}

#vission-mission-content h1{
    font-size: 32px;
    color: var(--font-color-black);
}

#vission-mission-content .mission-card{
    height: 100%;
}

#vission-mission-content .mission-card h6{
    font-size: 20px;
    font-weight: bold;
    height: 46px;
    color: var(--font-color-black);
}

#vission-mission-content .mission-card p{
    font-size: 16px;
    color: var(--font-color-black);
}

/* ---------- End Vission Mission Content ---------- */

/* ====================================================== */



/* ====================================================== */

/* ---------- Service Content ---------- */

#service-content{
    margin-top: 100px;
}

#service-content .service-description h1{
    font-size: 36px;
    color: var(--font-color-black);
}

#service-content .service-description p{
    font-size: 18px;
    margin: auto 0;
    color: var(--font-color-black);
}

#service-content .service-sample span{
    font-weight: bold;
    color: var(--font-color-black);
}

#service-content .service-sample p{
    font-size: 16px;
    margin: auto 0;
    color: var(--font-color-black);
}

/* ---------- End Service Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Partner Content ---------- */

#partner-content{
    margin-top: 100px;
}

#partner-content h1{
    font-size: 24px;
    color: var(--font-color-black);
}

#partner-content .logo img{
    width: 130px;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
}

/* ---------- End Partner Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Department Content ---------- */

#department-content{
    margin-top: 100px;
}

#department-content h1{
    font-size: 48px;
    color: var(--font-color-black);
}

#department-content .card-department{
    border-radius: 10px;
}

#department-content .department-title p{
    margin: auto 0;
    font-size: 20px;
    font-weight: bold;
    color: var(--font-color-black);
}

#department-content .department-short-description p{
    font-size: 16px;
    margin: auto 0;
    color: var(--font-color-black);
}

#department-content .department-point p{
    font-size: 18px;
    margin: auto 0;
    color: var(--font-color-black);
}

/* ---------- End Department Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Benefit Content ---------- */

#benefit-content{
    margin-top: 100px;
}

/* ---------- End Benefit Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- International Panelist Content ---------- */

#international-panelist-content{
    margin-top: 100px;
}

/* ---------- End International Panelist Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Mentor Content ---------- */

#mentor-content{
    margin-top: 100px;
}

/* ---------- End Mentor Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Testimony Content ---------- */

#testimony-content{
    margin-top: 100px;
}

.testimony-list h1{
    margin: auto 0;
}

.testimony-list .swiper {
    width: 100%;
    height: 100%;
}

.testimony-list .swiper-slide {
    font-size: 18px;
    background: var(--background-color-one);
    width: 100%;
    border-radius: 8px;
    padding: 20px;
}

.testimony-list .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.testimony-list .swiper-slide .profile p{
    margin: auto 0;
}

.testimony-list .swiper-slide p{
    margin-top: 25px;
}

/* ---------- End Testimony Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Contact Content ---------- */

#contact-content{
    margin-top: 100px;
}

#contact-content .contact-information h1{
    font-size: 18px;
    font-weight: bold;
    color: var(--font-color-black);
    margin-bottom: 0;
}

#contact-content .contact-information p{
    font-size: 18px;
    margin: auto 0;
    color: var(--font-color-black);
}

#contact-content .contact-form h1{
    font-size: 48px;
    color: var(--font-color-black);
}

#contact-content .contact-form p{
    font-size: 18px;
    margin: auto 0;
    color: var(--font-color-black);
}

/* ---------- End Contact Content ---------- */

/* ====================================================== */


/* ==========================================================================================*/
/* ====================================== END HOME PAGE =====================================*/
/* ==========================================================================================*/


/* =============================================================================================*/
/* ======================================== PRODUCT PAGE =======================================*/
/* =============================================================================================*/


/* ============================================ */

/* ------ Dropdown Content ------*/

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-menu {
    display: block;
    position: absolute;
    top: 45px;
    left: 0;
    background-color: var(--font-color-white);
    border: none;
    box-shadow: 0 8px 16px rgba(197, 197, 197, 0.25);
    font-size: 14px;
    padding: 8px;
    color: var(--font-color-grey);
    list-style: none;
    text-decoration: none;
    z-index: 999;
    width: 120px;
}

.dropdown-menu-item {
    padding: 8px 0;
    cursor: pointer;
}

.dropdown-menu-item:hover {
    background-color: #FAFAFA;
    border-radius: 6px;
}

/* ------ End Dropdown Content ------*/

/* ============================================ */


/* ====================================================== */

/* ---------- List Product Content ---------- */

.product-head-title{
    margin-top: 180px;
}

.product-head-title h1{
    font-size: 36px;
    color: var(--font-color-black);
    margin: auto 0;
}

.product-list-query{
    margin-top: 80px;
}

.product-list-query h5{
    font-size: 18px;
    margin: auto 0;
    color: var(--font-color-black);
}

.product-list-sell p{
    margin: auto 0;
    color: var(--font-color-black);
}

/* ---------- End List Product Content ---------- */

/* ====================================================== */


/* ====================================================== */

/* ---------- Detail Product Content ---------- */

.detail-product-back-to{
    margin-top: 140px;
}

.detail-product-back-to h1{
    font-size: 18px;
    cursor: pointer;
    margin: auto 0;
}

.detail-product{
    margin-top: 30px;
}

.detail-product-profile-head{
    margin-top: 25px;
}

.detail-product-profile-head h5{
    font-size: 18px;
    color: var(--font-color-black);
    margin: auto 0;
}

.detail-product-profile-head h1{
    font-size: 24px;
    color: var(--font-color-black);
    font-weight: bold;
    margin: auto 0;
}

.detail-product-profile-head p{
    font-size: 20px;
    margin: auto 0;
    color: var(--font-color-black);
}

.detail-product hr{
    margin: 40px 0;
}

.detail-product-profile-tile h2{
    font-size: 18px;
    color: var(--font-color-black);
    margin: auto 0;
    cursor: pointer;
}

.detail-product-profile-tile .active{
    font-weight: bold;
    color: var(--font-color-black);
}

.addition-detail-product{
    margin-top: 30px;
}

.description-detail-product p{
    font-size: 18px;
    margin: auto 0;
}

.benefit-detail-product p{
    font-size: 18px;
    margin: auto 0;
}

.review-detail-product p{
    font-size: 18px;
    margin: auto 0;
}

/* ---------- End Detail Product Content ---------- */

/* ====================================================== */



/* =============================================================================================*/
/* ====================================== END PRODUCT PAGE =====================================*/
/* =============================================================================================*/



/* =============================================================================================*/
/* ========================================= EVENT PAGE ========================================*/
/* =============================================================================================*/


/* ====================================================== */

/* ---------- Event Content ---------- */

.event-head-title{
    margin-top: 180px;
}

.event-head-title h1{
    font-size: 36px;
    color: var(--font-color-black);
    margin: auto 0;
}

.list-event{
    margin-top: 30px;
}

.list-event h1{
    font-size: 20px;
    font-weight: bold;
    margin: auto 0;
    color: var(--font-color-black);
}

.list-event h2{
    font-size: 18px;
    margin: auto 0;
    color: var(--font-color-black);
    cursor: pointer;
}

.list-event h2:hover{
    font-weight: bold;
}

.list-event p{
    font-size: 16px;
    margin: auto 0;
    color: var(--font-color-black);
}

/* ---------- End Event Content ---------- */

/* ====================================================== */


/* =============================================================================================*/
/* ======================================= END EVENT PAGE ======================================*/
/* =============================================================================================*/


/* ====================================================== */

/* ---------- Footer Content ---------- */

#footer-content .footer-profile p{
    font-size: 18px;
    color: var(--font-color-white);
    margin: auto 0;
}

#footer-content .footer-quick {
    gap: 25px;
    height: 100%;
}

#footer-content .footer-quick p{
    font-size: 20px;
    color: var(--font-color-white);
    margin: auto 0;
    cursor: pointer;
}

#footer-content .footer-quick p:hover{
    font-weight: 600;
}

#footer-content hr{
    margin-top: 35px;
    margin-bottom: 35px;
}

/* ---------- End Footer Content ---------- */

/* ====================================================== */